var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"value-chain-circle",style:(`width: ${_vm.radius * 2}px; height: ${
      _vm.radius * 2
    }px; background-color: ${_vm.background};`)},[(_vm.isEditMode || _vm.items.length)?_c('div',{staticClass:"value-chain-content-container"},[_vm._l((_vm.items),function(item,index){return _c('div',{key:`${index}-icon`,staticClass:"value-chain-item",style:(`left: ${_vm.radius - 30}px; transform: rotate(${
          (180 * index) / _vm.items.length
        }deg) translate(calc(${_vm.radius * 2}px * cos(${
          90 - (180 * index) / _vm.items.length
        }deg))) rotate(-${(180 * index) / _vm.items.length}deg)`)},[_c('trace-services-icon',{attrs:{"traceName":item.icon}})],1)}),_vm._l((_vm.items),function(item,index){return _c('div',{key:`${index}-content`,staticClass:"value-chain-content",style:(`text-align: ${
          (360 * index) / _vm.items.length === 0 ||
          (360 * index) / _vm.items.length === 180
            ? 'center'
            : (360 * index) / _vm.items.length > 180
            ? 'right'
            : 'left'
        }; top: ${_vm.radius - 35}px; left: ${
          _vm.radius -
          ((360 * index) / _vm.items.length > 180
            ? 130 / 2 + 40
            : (360 * index) / _vm.items.length == 0 ||
              (360 * index) / _vm.items.length == 180
            ? 130 / 2
            : 30)
        }px; transform: rotate(${
          (360 * index) / _vm.items.length - 90
        }deg) translate(${_vm.radius + 95}px) rotate(${-(
          (360 * index) / _vm.items.length -
          90
        )}deg)`)},[_c('div',{staticClass:"content-title"},[_vm._v(" "+_vm._s(item.label)+" ")]),(!_vm.isEditMode)?_c('v-chip',{staticClass:"content-dropdown",style:(`justify-content: ${
            (360 * index) / _vm.items.length === 0 ||
            (360 * index) / _vm.items.length === 180
              ? 'center'
              : (360 * index) / _vm.items.length > 180
              ? 'flex-end'
              : 'flex-start'
          };`),attrs:{"color":"white"}},[(Array.isArray(item.value))?_c('truncate-hovering',{attrs:{"text":item.value.length > 1
                ? `${item.value.length} selected`
                : item.value[0]?.name,"length":18}}):_c('truncate-hovering',{attrs:{"text":item.value?.name,"length":18}})],1):_c('v-autocomplete',{style:(`width: ${(150 * _vm.radius) / 200}px;`),attrs:{"background-color":"white","rounded":"","search-input":_vm.focusedDropdown === item.id ? _vm.dropdownOptions.search : '',"items":(item.value && !Array.isArray(item.value)
              ? [item.value]
              : Array.isArray(item.value)
              ? [...item.value]
              : []
            ).concat(
              _vm.dropdownOptions.list.filter((listItem) =>
                item.value && !Array.isArray(item.value)
                  ? listItem.id !== item.value.id
                  : !item.value?.find((value) => value.id === listItem.id)
              )
            ),"item-text":"name","item-value":"id","label":"Add company","multiple":item.multiple,"hide-no-data":_vm.dropdownOptions.loading,"loading":_vm.dropdownOptions.loading && _vm.focusedDropdown === item.id,"disabled":item.id === 'delivery',"dense":"","chips":"","small-chips":"","clearable":"","return-object":""},on:{"update:search-input":_vm.updateDropdownSearch,"focus":function($event){_vm.focusedDropdown = item.id},"change":function($event){return _vm.onChangeInput(item, ...arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(!Array.isArray(item.value) || item.value.length < 2)?_c('div',_vm._b({staticClass:"select-component"},'div',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")]):(data.index === 0)?_c('div',{staticClass:"select-component"},[_vm._v(" "+_vm._s(`${item.value.length} selected`)+" ")]):_vm._e()]}},{key:"append-item",fn:function(){return [_c('infinite-loading',{key:`infinite-toggle-${_vm.dropdownOptions.search}`,ref:"InfiniteLoading",refInFor:true,on:{"infinite":_vm.getGlobalSuppliersList}},[(_vm.dropdownOptions.list.length)?_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" No more data ")]):_vm._e(),(!_vm.dropdownOptions.list.length)?_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" No data available ")]):_vm._e(),(_vm.dropdownOptions.list.length)?_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" No more data ")]):_vm._e(),(!_vm.dropdownOptions.list.length)?_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" No data available ")]):_vm._e()])]},proxy:true}],null,true),model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)})],2):_vm._e(),_c('div',{staticClass:"value-chain-title",style:(`
      width: ${_vm.radius * 2 - 60}px;
      overflow-wrap: break-word;
      font-size: ${_vm.getFontSize(_vm.title, _vm.radius)}px;
      line-height: 1.2;
    `)},[_vm._v(" "+_vm._s(_vm.title)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }